import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

type AdvancedFilterModal = {
	open: boolean
	showTab: 0 | 1
}

const defaults: AdvancedFilterModal = {
	open: false,
	showTab: 0
}

const contextName = 'ADVANCED_FILTER_MODAL_CTX'

export function setAdvancedFilterModalState(
	data?: AdvancedFilterModal
): Writable<AdvancedFilterModal> {
	const state = writable<AdvancedFilterModal>({
		...defaults,
		...(data && { ...data })
	})
	setContext(contextName, state)
	return state
}

export function getAdvancedFilterModalState(): Writable<AdvancedFilterModal> {
	return getContext<Writable<AdvancedFilterModal>>(contextName)
}
